import firebase from "firebase/app";
import "firebase/analytics";
import 'firebase/database';
import 'firebase/firestore';
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default function (evento, novo_valor, item_id, id_curso, usuario, pagina) {
    db.collection('log_files')
    .add({
        evento: evento,
        novo_valor: novo_valor,
        item_id: item_id,
        id_curso: id_curso,
        pagina: pagina,
        usuario: usuario,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
        console.log(`Log: usuário: ${usuario}, ${evento}, item ${item_id}, para o valor: ${novo_valor}, na página ${pagina}`);
    })
    .catch((error) => {
        console.log('Erro:', error)
    })
}
// logFirebase(evento, novo_valor, item_id, this.$route.params.id_firebase, usuario,  $route.path)
// logFirebase("Novo valor", novo_valor, item_id, id_curso, usuario,  'Forms Conteudista')
// logFirebase("login", '', 'login', '', usuario,  'login_pg')