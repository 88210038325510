<template>
    <div class="container">
        
        <div class="row" style="height: 80vh">
            <div class="col-11 col-lg-6 col-xl-5 mx-auto p-5 mt-5 caixa-login pt-5">
                <img id="logo" src="https://www.uninove.br/logo-uninove.svg" alt="Logo Image" height="70px">

                <b-form-group
                    label="Insira seu PIN de preenchimento para iniciar"
                    label-for="input-pin"
                    class="mb-0 mt-5"
                    >
                    <b-form-input
                        id="input-pin"
                        v-model="input_cpf"
                        placeholder=""
                        :formatter="formatter"
                        :state="verificacao"
                        @keyup.enter="logIn">
                    ></b-form-input >
                    <b-form-invalid-feedback id="input-pin">
                        Não foi possível verificar seu PIN de acesso
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-button class="m-1 mt-3" @click="logIn()" variant="outline-primary" style="width: fit-content">LOGIN</b-button>
            </div>
        </div>

        <b-modal id="modal-login" title="" hide-header centered hide-footer>
            <div style="align-self: right; text-align: center"><b-button disabled  variant="primary">
                <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
                <strong>   Carregando...</strong>
            </b-button></div>
        </b-modal>
        
        <!-- <b-button class="m-1" @click="logOut()" variant="outline-primary" style="width: fit-content">LogOut</b-button> -->
    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';
import logFirebase from "@/views/testes/logFirebase.js";


// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    data() {
        return {
            input_cpf: '',
            verificacao: null
        }
    },
    methods:{
        logIn(){
            this.verificacao = null
            this.$bvModal.show('modal-login')
            // console.log(this.input_cpf)
            // Esse usuário existe?
            let users = []
            db.collection("usuarios")
            .where("cpf", "==", this.input_cpf.trim())
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let user = doc.data()
                    user['id'] = doc.id
                    users.push(user)
                });
                if(users.length > 0){
                        console.log('Sucesss auth')
                        logFirebase("login", users[0], 'login', 'null', users[0].nome, 'login_pg')
                        localStorage.name = users[0].nome,
                        localStorage.id = users[0].id
                        localStorage.perfil = users[0].perfil
                        localStorage.cursos_permitidos = users[0].cursos_permitidos
                        setTimeout(() => {  
                            this.$router.replace({path: "/"})
                        }, 2000)
                        
                    } else {
                        console.log('Blocked auth')
                        setTimeout(() => {  
                            this.verificacao = false
                            this.$bvModal.hide('modal-login')
                        }, 2000)
                    }
                
            })
            .catch((error) => {
                console.log("Erro ao solicitar ao firebase: ", error);
            });
           
            
        },

        logOut(){
            localStorage.removeItem('name')
            localStorage.removeItem('timestamp')
            localStorage.removeItem('perfil')
        },

        formatter(value) {
            return value.toUpperCase()
        }

    },
    beforeMount(){
        if(localStorage.name){
            // console.log('usuario logado')
        } else {
            // console.log('usuario NÃOOO logado')
        }
    }
    
}
</script>

<style scoped>
.caixa-login {
    box-shadow: hsla(0,0%,40.8%,0.5) 0 3px 8px;
    border-radius: 7px
}

/* Extra Small */
@media (min-width: 0px) and (max-width: 600px){
    .test{
        border: #32b336 7px solid !important;
    }
    .caixa-login {
        box-shadow: none;
        border-radius: 7px
    }

}
    /* Small */
@media screen and (min-width: 576px){
    .test{
        border: #ffa600 7px solid !important;
    }
    .caixa-login {
        box-shadow: none;
        border-radius: 7px
    }
}

/* Medium*/
@media screen and (min-width: 768px){
    .test{
        border: #41a7ff 7px solid !important;
    }
    .caixa-login {
        box-shadow: none;
        border-radius: 7px
    }
}

/* Large*/
@media screen and (min-width: 992px){
    .test{
        border: yellow 7px solid !important;
    }
    .caixa-login {
        box-shadow: hsla(0,0%,40.8%,0.5) 0 3px 8px;
        border-radius: 7px
    }
}

/* Extra large */
@media screen and (min-width: 1200px){
    .test{
        border: #ff0053 7px solid !important;
    }
}
</style>